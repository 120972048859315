exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-history-2018-tsx": () => import("./../../../src/pages/history/2018.tsx" /* webpackChunkName: "component---src-pages-history-2018-tsx" */),
  "component---src-pages-history-2019-tsx": () => import("./../../../src/pages/history/2019.tsx" /* webpackChunkName: "component---src-pages-history-2019-tsx" */),
  "component---src-pages-history-2022-tsx": () => import("./../../../src/pages/history/2022.tsx" /* webpackChunkName: "component---src-pages-history-2022-tsx" */),
  "component---src-pages-history-2023-tsx": () => import("./../../../src/pages/history/2023.tsx" /* webpackChunkName: "component---src-pages-history-2023-tsx" */),
  "component---src-pages-history-2024-tsx": () => import("./../../../src/pages/history/2024.tsx" /* webpackChunkName: "component---src-pages-history-2024-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-competition-tsx": () => import("./../../../src/templates/competition.tsx" /* webpackChunkName: "component---src-templates-competition-tsx" */),
  "component---src-templates-workshop-tsx": () => import("./../../../src/templates/workshop.tsx" /* webpackChunkName: "component---src-templates-workshop-tsx" */)
}

